<template>
  <div class="w-100 font-family-navbar">
    <div
      v-if="isTikTokAppCenter"
      class="horizontal-menu-container navbar-container d-flex content align-items-center font-14"
    >
      <b-navbar-nav
        class="bg-white d-flex justify-content-between align-items-center w-100 horizontal-menu-content p-2"
      >
        <b-img
          :src="require('@/assets/images/pages/overview/lg-ecomdy-tiktok.svg')"
          height="28"
        />
        <div class="d-flex">
          <switch-partner-view
            v-if="isPartnerViewMode !== null && isPartnerViewMode !== 'true'"
            id="btn-switch-partner-view"
            data-testid="btn-switch-partner-view"
            class="d-none d-md-block mx-1"
          />

          <b-nav
            v-if="!hasStepGuide && isPartnerViewMode !== 'true'"
            class="nav align-items-center ml-auto list-horizontal-menu"
            align="right"
          >
            <b-nav-item
              v-for="(menu, index) in listNavbarItem"
              :key="index"
              :active="routeActive === menu.route"
              @click="$router.push({ name: menu.route })"
            >
              {{ menu.title }}
            </b-nav-item>
          </b-nav>
        </div>

        <div class="d-flex align-items-center">
          <switch-partner-view
            v-if="isPartnerViewMode !== null && isPartnerViewMode === 'true'"
            id="btn-switch-partner-view"
            data-testid="btn-switch-partner-view"
            class="d-none d-md-block mx-1"
          />
          <app-help-center
            v-if="showAppHelpCenterComponent"
            data-testid="btn-help"
          />
          <div
            class="d-flex"
            @click="handelRedirectFullPage"
          >
            <p class="mb-0 ml-1 text-go-to-full-page">
              Full page
            </p>
            <img
              class="discord-logo cursor-pointer text-go-to-full-page"
              :src="require('@/assets/images/icons/ic-redirect.svg')"
              alt="image"
              :width="16"
              style="margin-left: 4px"
            >
          </div>
        </div>
      </b-navbar-nav>
    </div>

    <div
      v-else
      class="navbar-container d-flex content align-items-center"
    >
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>

      <!-- Left Col -->
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      >
        <app-breadcrumb />
      </div>

      <b-navbar-nav class="nav align-items-center ml-auto">
        <switch-partner-view
          v-if="isPartnerViewMode !== null"
          id="btn-switch-partner-view"
          data-testid="btn-switch-partner-view"
          class="d-none d-md-block mr-1"
        />

        <div v-if="subscriptionMode">
          <current-plan
            v-if="isPartnerViewMode !== 'true'"
            id="btn-current-plan"
            data-testid="btn-current-plan"
          />
        </div>

        <app-help-center
          v-if="showAppHelpCenterComponent"
          data-testid="btn-help"
        />

        <div class="locale-controller">
          <locale
            v-if="isPancakeUser"
            id="select-locale"
            data-testid="select-locale"
          />
        </div>

        <b-nav-item-dropdown
          id="btn-info-user"
          data-testid="btn-info-user"
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
          style="padding-left: 5px"
        >
          <template #button-content>
            <b-avatar
              id="avatar-user"
              data-testid="avatar-user"
              size="26"
              variant="light-secondary"
            >
              <feather-icon icon="UserIcon" />
            </b-avatar>
            <div
              id="info-user"
              data-testid="info-user"
              class="d-sm-flex d-none user-nav"
            >
              <p
                class="user-name font-weight-600 mb-0 font-14"
                style="margin-left: 0"
              >
                {{ fullName }}
              </p>
            </div>
          </template>
          <b-dropdown-item
            v-if="!(hasStepGuide && isFromTikTok)"
            link-class="d-flex align-items-center"
            :to="{ name: 'profile' }"
          >
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            <span>{{ $t("layout.textProfile") }}</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="onLogout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>{{ $t("layout.textLogOut") }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>

    <AlertNotification
      v-if="isShowNotification"
      class="navbar-alert"
      :notifications="notifications"
      @delete="deleteNotification"
    />

    <live-chat />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle,camelcase */

import {
  BImg,
  BLink,
  BNavbarNav,
  BNav,
  BNavItemDropdown,
  BDropdownItem,
  BNavItem,
  BAvatar,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppHelpCenter from '@core/components/app-help/AppHelp.vue'
import get from 'lodash/get'
import { STYLES, TRANSACTION_TYPE } from '@/constants'
import partnerMixin from '@/mixins/partnerMixin'
import billingMixin from '@/mixins/billingMixin'
import envMixin from '@/mixins/envMixin'
import authMixin from '@/mixins/authMixin'
import { Crisp } from 'crisp-sdk-web'
import LiveChat from '@/components/LiveChat.vue'
import locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import _isEmpty from 'lodash/isEmpty'
import navMenuItems from '@/navigation/vertical'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import { loadThemeConfig } from '@/libs/common'
import AlertNotification from '@/components/AlertNotification.vue'
import CurrentPlan from './CurrentPlan.vue'
import SwitchPartnerView from './SwitchPartnerView.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')
const { mapActions: mapActionsPayment } = createNamespacedHelpers('payment')
Crisp.configure('5ce2e4e9-8d32-4ab3-8bce-e0679285b671', {
  autoload: false,
})
export default {
  components: {
    AlertNotification,
    SwitchPartnerView,
    CurrentPlan,
    AppBreadcrumb,
    BImg,
    BLink,
    BNavbarNav,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    AppHelpCenter,
    LiveChat,
    locale,
  },
  mixins: [partnerMixin, billingMixin, envMixin, authMixin, stepGuideMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      navMenuItems,
      routeActive: '',
      intervalId: null,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'plansStatus',
      'generalConfigs',
      'isTikTokAppCenter',
      'status',
      'notifications',
    ]),

    fullName() {
      const fullName = get(this.user, ['data', 'fullName'], '')
      const email = get(this.user, ['data', 'email'], '')
      return fullName || email
    },

    // avatarURL() {
    //   return get(this.user, ['data', 'imageUrl'], defaultAvavar)
    // },

    listNavbarItem() {
      const navbarConvert = [
        'nav-overview',
        'nav-my-ads-account',
        'nav-product-link',
      ]
      return navbarConvert.map(menu => this.navMenuItems.find(item => menu === item.id))
    },
  },

  watch: {
    user: {
      handler(val) {
        if (val?.data?.localUserId && this.generalConfigs?.system) {
          this.setLiveChat(val)
        }
      },
      deep: true,
      immediate: true,
    },
    generalConfigs: {
      handler(val) {
        if (this.user?.data?.localUserId && val?.system) {
          this.setLiveChat(this.user)
        }
      },
      deep: true,
      immediate: true,
    },
    $route: {
      immediate: true,
      handler(val) {
        this.SGStripeHandler()
        this.handleBlockedUser()

        this.routeActive = val.name
      },
    },
  },

  async created() {
    await this.getAccountInfo()
    if (this.isFromTikTok) {
      loadThemeConfig(STYLES.TIKTOK_APP_CENTER)
    }
    this.getGeneralConfig()
    this.getAccountReport()
    this.fetchHistory() // get history add fund user for step guide, side bar.
    if (this.isShowNotification) {
      this.getNotifications({ status: 'unread' }) // Call notification first time
      this.intervalId = setInterval(() => { this.getNotifications({ status: 'unread' }) }, 1000 * 60) // Call back every 1 minute
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    ...mapActions([
      'logout',
      'getAccountInfo',
      'getGeneralConfig',
      'getAccountReport',
      'updateAccount',
      'getNotifications',
      'readNotifications',
    ]),
    ...mapActionsPayment(['getHistoryLimit']),
    onLogout() {
      this.logout()
    },

    setLiveChat(user) {
      if (this.isOnlyEcomdyPlatform) {
        this.initFreshChat(user)
        // if (user?.data?.localUserId === 1062 || user?.data?.localUserId === 3256) {
        //   this.initLiveChat(user)
        // }
      }

      this.handlePartnerView()
      this.handleBlockedUser()
      this.SGStripeHandler()
    },

    initFreshChat(val) {
      const plugin = document.createElement('script')
      plugin.setAttribute('src', 'https://wchat.freshchat.com/js/widget.js')
      plugin.onload = () => {
        const email = get(val, ['data', 'email'], '')
        const localUserId = get(val, ['data', 'localUserId'], null)
        const restoreId = get(val, ['data', 'freshChatRestoreId'], null)
        const fullName = get(val, ['data', 'fullName'], '')
        const adsAccount = get(val, ['data', 'adsAccounts'], [])
        const phoneData = get(val, ['data', 'contactInfo', 'phone'], 'N/A')
        const totalSpent = get(val, ['data', 'totalSpending'], 'N/A')
        const userCountryCode = get(val, [
          'data',
          'signupTracking',
          'countryName',
        ])
        const userIp = get(val, ['data', 'signupTracking', 'ip'])
        const userAgent = get(val, ['data', 'signupTracking', 'userAgent'])
        const amId = get(val, ['data', 'amId'], 'N/A')
        const ecomdyBalance = get(val, ['data', 'balance'], 'N/A')
        const profileBaseUrl = 'https://tiktok-admin.ecomdymedia.com'
        let platform = 'TTM'
        if (this.isFromTikTok) {
          platform = 'TTAC'
        } else if (this.isPancakeUser) {
          platform = 'Pancake'
        }

        if (email && window.fcWidget) {
          window.fcWidget.init({
            token: 'b7c5a45f-b29b-4853-ac30-950b2842854a',
            host: 'https://wchat.freshchat.com',
            locale: 'en',
            externalId: localUserId,
            restoreId,
            email,
            firstName: `${localUserId}_${userCountryCode}_`,
            lastName: fullName,
            phone: this.getPhoneData('phone'),
          })

          window.fcWidget.user.setProperties({
            phone: this.getPhoneData('phone'),
            ecomdyBalance,
            plan: this.getPlanName,
            totalSpent: `${totalSpent}$`,
            totalAdsAccount: Array.isArray(adsAccount) ? adsAccount.length : 0,
            phoneData,
            profileUrl: `${profileBaseUrl}/user/${localUserId}`,
            ip: userIp,
            userAgent,
            amId,
            platform,
          })
          this.saveUserInfoToFreshChat()
        }
      }
      plugin.async = true
      return document.head.appendChild(plugin)
    },

    // initLiveChat(val) {
    //   const email = get(val, ['data', 'email'], '')
    //   const status = get(val, ['data', 'status'], '')
    //   const localUserId = get(val, ['data', 'localUserId'], null)
    //   const fullName = get(val, ['data', 'fullName'], '')
    //   const adsAccount = get(val, ['data', 'adsAccounts'], [])
    //   const totalSpent = get(val, ['data', 'totalSpending'], 'N/A')
    //   const userCountryCode = get(val, ['data', 'signupTracking', 'countryName'])
    //   const userIp = get(val, ['data', 'signupTracking', 'ip'])
    //   const userAgent = get(val, ['data', 'signupTracking', 'userAgent'])
    //   const amId = get(val, ['data', 'amId'], 'N/A')
    //   const ecomdyBalance = get(val, ['data', 'balance'], 'N/A')
    //   const profileBaseUrl = 'https://tiktok-admin.ecomdymedia.com'
    //   const nickName = `${localUserId}_${userCountryCode}_${fullName}`
    //   if (localUserId) {
    //     Crisp.session.setData({
    //       user_id: localUserId,
    //       status,
    //       amId,
    //       ecomdyBalance,
    //       totalSpent: `${totalSpent}$`,
    //       totalAdsAccount: Array.isArray(adsAccount) ? adsAccount.length : 0,
    //       user_ip: userIp,
    //       user_agent: userAgent,
    //       profileUrl: `${profileBaseUrl}/user/${localUserId}`,
    //     })
    //     Crisp.setTokenId(localUserId)
    //     Crisp.user.setEmail(email)
    //     Crisp.user.setNickname(nickName)
    //     Crisp.user.setPhone(`+${this.getPhoneData('countryCode')}${this.getPhoneData('phone')}`)
    //     Crisp.load()
    //   }
    // },

    // initTicket(val) {
    //   if (this.isFromTikTok && window.FreshworksWidget) {
    //     const email = get(val, ['data', 'email'], '')
    //     const localUserId = get(val, ['data', 'localUserId'], null)
    //     const fullName = get(val, ['data', 'fullName'], '')
    //     const userCountryCode = get(val, ['data', 'signupTracking', 'country'])
    //     window.FreshworksWidget('identify', 'ticketForm', {
    //       name: `${localUserId}_${userCountryCode}_${fullName}`,
    //       email,
    //     })
    //     window.FreshworksWidget('disable', 'ticketForm', ['email'])
    //     window.FreshworksWidget('hide', 'ticketForm', ['name'])
    //   }
    // },

    saveUserInfoToFreshChat() {
      window.fcWidget.user.get(resp => {
        const status = resp && resp.status
        if (status !== 200) {
          window.fcWidget.on('user:created', createdResp => {
            const createdStatus = createdResp && createdResp.status
            const createdData = createdResp && createdResp.data
            if (createdStatus === 200) {
              if (createdData.restoreId) {
                // Update restoreId into database
                const params = {
                  ...this.user.data,
                  freshChatRestoreId: createdData.restoreId,
                }
                this.updateAccount(params)
              }
            }
          })
        }
      })
    },

    getPhoneData(type) {
      const contactInfo = get(this.user, ['data', 'contactInfo'], {})
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = phoneElm
          switch (type) {
            case 'phone':
              return phone
            case 'countryCode':
              return countryCode
            case 'countryAbbr':
              return countryAbbr
            default:
              return ''
          }
        }
      }
      return ''
    },

    async fetchHistory() {
      const params = {
        page: 1,
        size: 5,
        types: [
          TRANSACTION_TYPE.RECHARGE_USER_BALANCE,
          TRANSACTION_TYPE.ADMIN_FIX_RECHARGE_MONEY,
        ],
      }
      await this.getHistoryLimit(params)
    },

    SGStripeHandler() {
      const needToAddCardToStripeUS = get(this.user, [
        'data',
        'needToAddCardToStripeUS',
      ])
      if (needToAddCardToStripeUS) {
        this.$router.push('/reactive-stripe')
      }
    },

    handleBlockedUser() {
      const statusUser = get(this.user, ['data', 'status'])
      if (statusUser === 'blocked') {
        this.$router.push('/user-block')
      }
    },

    handlePartnerView() {
      const partnerViewMode = localStorage.getItem('partnerView')
      const partnership = get(this.user, ['data', 'partnership'])
      const partnerPage = 'partner-management'
      const memberPage = 'member-detail'
      const { name } = this.$route

      if (
        (!partnerViewMode && !_isEmpty(partnership))
        || (!(name?.includes(partnerPage) || name?.includes(memberPage))
          && partnerViewMode === 'true')
      ) {
        localStorage.setItem('partnerView', 'true')
        window.location.href = '/partner-management'
      }
    },

    handelRedirectFullPage() {
      const idToken = localStorage.getItem('id_token')
      window.open(`${process.env.VUE_APP_SITE_URL}?id_token=${idToken}`)
    },

    async deleteNotification(id) {
      await this.readNotifications({ id })
      if (this.status) {
        const notificationIndex = this.notifications.map(item => item._id).indexOf(id)
        if (notificationIndex > -1) {
          this.notifications.splice(notificationIndex, 1)
        }
      }
    },

    // loadLanguage() {
    //   const lang = localStorage.getItem('locale')
    //
    //   if (!lang && this.isPancakeUser) {
    //     const { language } = navigator
    //
    //     if (language.includes('vi')) {
    //       localStorage.setItem('locale', 'vi')
    //       i18n.locale = 'vi'
    //     } else {
    //       localStorage.setItem('locale', 'en')
    //       i18n.locale = 'en'
    //     }
    //   }
    // },
  },
}
</script>
<style lang="scss" scoped>
.font-family-navbar {
  font-family: var(--font-family-sans-serif);
}
.locale-controller {
  margin: 0 13px;
}

.horizontal-menu-container {
  .horizontal-menu-content {
    border-radius: var(--border-radius-base);
  }

  .list-horizontal-menu {
    .nav-link {
      padding: 0 !important;
      margin-left: 1rem;

      @media (min-width: 1200px) {
        margin-left: 2rem;
      }
    }
    .active {
      background-color: transparent;
      color: var(--text-live-chat-color) !important;

      &::after {
        content: "";
        height: 2px;
        width: 100%;
        display: inline-block;
        position: absolute;
        background: linear-gradient(
          128.33deg,
          var(--button-color-start) -29.89%,
          var(--button-color-end) 98.77%
        );
        left: 0;
        bottom: -4px;
      }
    }
  }
}

.text-go-to-full-page {
  color: var(--text-live-chat-color) !important;
  cursor: pointer;
}

.navbar-alert {
  @media (max-width: 767px) {
    display: none !important;
  }
}
</style>
