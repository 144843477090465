<template>
  <btn-loading
    id="trigger-current-plan"
    data-testid="btn-current-plan"
    variant-convert="outline-warning"
    pill
    size="sm"
    class="font-medium d-flex align-items-center justify-content-center font-small-2"
    style="margin-right: 13px"
    to="/billing"
  >
    <img
      v-if="noPlan && !needToBeReviewed"
      alt="tick"
      :src="require('@/assets/images/pages/billing/crown-active.svg')"
      class="img-crown"
    >
    {{ noPlan && !needToBeReviewed ? 'Upgrade' : getPlanName }}
  </btn-loading>
</template>

<script>
/* eslint-disable camelcase */
import billingMixin from '@/mixins/billingMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BtnLoading,
  },
  mixins: [billingMixin],
}
</script>
<style lang="scss" scoped>
  .img-crown{
    width: 17px;
    margin-right: 6px;
  }
</style>
