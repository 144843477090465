<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in itemsCustom"
      :key="item.header || item.title"
      :id="item.id"
      :style="{cursor: hasStepGuide ? 'not-allowed': ''}"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import partnerMixin from '@/mixins/partnerMixin'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },

  mixins: [stepGuideMixin, partnerMixin, envMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },

  computed: {
    ...mapGetters(['user']),

    itemsCustom() {
      let itemConverted = this.handleShowNav(this.items)

      if (!this.hasAcl) {
        itemConverted = itemConverted.filter(item => item.route !== 'partner-management-contact-info')
      }

      if (this.isPartner) { // Hide all ref route if user is partner
        itemConverted = itemConverted.filter(item => !item.route.includes('referral'))
      }

      if (this.hasStepGuide && this.isPartnerViewMode !== 'true') {
        itemConverted = itemConverted.map(item => ({
          ...item,
          disabled: this.inValidUrl(item.route), // disable menu item if user is in step guide
        }))
      }

      return itemConverted
    },
  },

  methods: {
    handleShowNav(router) {
      if (this.isPartnerViewMode === 'true') { // filter nav just for partner.
        return router.filter(item => {
          if (this.hasAcl) {
            if (this.hasFormWhiteList) {
              return item.route.startsWith('partner') && !item.route.includes('partner-information')
            }

            return item.route.startsWith('partner')
                    && !item.route.includes('partner-info-url')
          }
          if (this.hasFormWhiteList) {
            return item.route.startsWith('partner')
                    && !item.route.includes('partner-management-product-link')
          }
          return item.route.startsWith('partner')
                  && !item.route.includes('partner-management-product-link')
                  && !item.route.includes('partner-info-url')
        })
      }
      let routerSeller = router.filter(item => {
        if (this.isPartner) {
          return !item.route.startsWith('partner') && !item.route.startsWith('referral-introduce') // Not show referral link if partner is seller
        }
        return !item.route.startsWith('partner')
      })

      if (!(this.isEcomdyPlatform || this.isGyxenPlatform) || this.isPartner || this.isExclusivePartner) {
        routerSeller = routerSeller.filter(item => item.route !== 'affiliate-program')
      }

      if (!this.subscriptionMode) {
        routerSeller = routerSeller.filter(item => item.route !== 'billing')
      }

      if (!this.refMode) {
        routerSeller = routerSeller.filter(item => !item.route.includes('referral'))
      }

      if (!this.pixelMode) {
        routerSeller = routerSeller.filter(item => item.route !== 'pixel-management')
      }

      if (!this.productLinkMode) {
        routerSeller = routerSeller.filter(item => item.route !== 'product-link')
      }

      if (this.isMember) {
        return routerSeller.filter(item => item.route !== 'referral-introduce') // filter nav just for member.
      }
      return routerSeller.filter(item => item.route !== 'referral-partner-info') // filter nav just for normal user.
    },

    inValidUrl(route) {
      return !route.includes('referral') && !route.includes('faq') && !route.includes('pixel') && !route.includes('affiliate')
    },
  },
}
</script>
